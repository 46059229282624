import Vue from "vue";
import VueI18n from 'vue-i18n'
import zh from "./zh"
import en from "./en"
import de from "./de"
import fr from "./fr"
import py from "./py"
import es from "./es"
import jp from "./jp"
import kor from "./kor"
import al from "./al"
import it from "./it"
import por from "./por"
Vue.use(VueI18n);   // 全局注册国际化包
// 准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || "en",
    messages: {zh, en, de, fr, py, es, jp, kor, al, it, por}
});

export default i18n
