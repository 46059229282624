export default {
    home:{
        navs: {
            product: "produtos",
            about: "sobre nós",
            contact: "Contate-nos",
        },
        article: {
            policy: "política",
            and: "e",
            agreement: "Termo",
        }
    },
    download: "Download",
    app: {
        title: "APP de produtos adultos inteligentes - Nantasia",
        content: "Personalização personalizada, experiências diversas",
    },
    data: {
        title: "O aplicativo Nantasia Adult Products é nosso principal produto e foi colocado na Apple Store e no Google Play",
        dataOne: "Cliente",
        dataTwo: "Países e regiões abrangidos",
        dataThree: "App se adapta ao idioma nacional",
    },
    func: {
        title: "Módulo de função do aplicativo",
        mode1: "Modo clássico",
        mode2: "interação remota",
        mode3: "modo de música",
        mode4: "modo de vídeo",
        mode5: "modo de curso",
    },
    service: {
        title: "Módulo de operação do aplicativo",
        mode1: "Exibição publicitária exclusiva",
        mode2: "Recomendações exclusivas de novos produtos",
        mode3: "Sistema exclusivo de atendimento ao cliente",
        mode4: "Personalização exclusiva da interface do usuário",
        mode5: "Personalização de jogo exclusiva",
    },
    manage: {
        title: "Back-end de gerenciamento de comerciante de aplicativos",
        t1: "Sistema de gerenciamento de commodities",
        t2: "Sistema de gerenciamento de atendimento ao cliente",
        t3: "Sistema de gerenciamento de membros",
        t4: "Sistema de gerenciamento de publicidade",
        c1: "Gerencie produtos com um clique",
        c2: "Compreensão individual em tempo real das necessidades do cliente",
        c3: "Capture membros-alvo em tempo real",
        c4: "Mais colunas de publicidade são exibidas",
        note1: "Nantasia fornece um sistema de gerenciamento de back-end independente para cada comerciante cooperativo.",
        note2: "Os comerciantes têm o direito de operar de forma independente e fazer o possível para atender às necessidades operacionais de cada comerciante parceiro.",
    },
    about: {
        title: "sobre nós",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. é uma empresa de pesquisa e desenvolvimento de tecnologia com foco em soluções Bluetooth para produtos adultos.",
        team1: "O líder de tecnologia de software da equipe fundadora é do Grupo Alibaba. Ele já trabalhou no nível Alibaba P9 e desenvolveu quase mais de 150 conjuntos de sistemas de shopping de comércio eletrônico, sistemas SAAS de shopping, sistemas de rastreabilidade de blockchain, sistemas de troca de blockchain, etc.",
        team2: "Estamos empenhados em fornecer aos clientes uma gama completa de serviços de desenvolvimento de software personalizados para ajudá-los a alcançar uma concorrência de produtos diferenciada e um crescimento sustentado do mercado. Acreditamos que através dos nossos esforços e inovação, podemos trazer mais possibilidades e oportunidades para a indústria de produtos para adultos.",
    },
    contact: {
        title: "Contate-nos",
        contact1: "Pessoa de contato: Sr. Peng",
        contact2: "Contato: +(86) 13510415119",
        contact3: "E-mail de contato: global.nantasia@gmail.com",
        contact4: "Endereço: Sala 216, 2º andar, Edifício A, Fase 2, Cidade Shanhai Yunhui, No. 1171 Xuegang Road, Comunidade Xiangjiaotang, Bantian Street, Longgang District, Cidade de Shenzhen, Província de Guangdong (Saída b da Estação de Metrô Gangtou)",
        service: "Atendimento ao cliente WeChat"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Todos os direitos reservados"
    }

};
