export default {
    home:{
        navs: {
            product: "продукт",
            about: "о нас",
            contact: "связаться с нами",
        },
        article: {
            policy: "политика",
            and: "и",
            agreement: "протокол",
        }
    },
    download: "скачать",
    app: {
        title: "интеллектуальных продуктов для взрослых",
        content: "Персонализированная настройка, разнообразный опыт",
    },
    data: {
        title: "Приложение Nantasia Adult Products является нашим флагманским продуктом и размещено в Apple Store и Google Play.",
        dataOne: "Клиент",
        dataTwo: "Охваченные страны и регионы",
        dataThree: "Язык адаптации",
    },
    func: {
        title: "Функциональный модуль приложения",
        mode1: "Классический режим",
        mode2: "удаленное взаимодействие",
        mode3: "музыкальный режим",
        mode4: "видео режим",
        mode5: "режим хода",
    },
    service: {
        title: "Модуль работы приложения",
        mode1: "Эксклюзивный рекламный показ",
        mode2: "Эксклюзивные рекомендации по новым продуктам",
        mode3: "Эксклюзивная система обслуживания клиентов",
        mode4: "Эксклюзивная настройка пользовательского интерфейса",
        mode5: "Эксклюзивная настройка игрового процесса",
    },
    manage: {
        title: "Модуль работы приложения",
        t1: "Система управления товарами",
        t2: "Система управления обслуживанием клиентов",
        t3: "Система управления участниками",
        t4: "Система управления рекламой",
        c1: "Управляйте продуктами в один клик",
        c2: "Индивидуальное понимание потребностей клиентов в режиме реального времени",
        c3: "Захватите целевых участников в режиме реального времени",
        c4: "Отображение большего количества рекламных столбцов",
        note1: "Nantasia предоставляет независимую серверную систему управления для каждого кооперативного торговца.",
        note2: "Продавцы имеют право действовать независимо и делать все возможное для удовлетворения операционных потребностей каждого продавца-партнера.",
    },
    about: {
        title: "о нас",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. — научно-исследовательское предприятие в области технологий, специализирующееся на решениях Bluetooth для товаров для взрослых.",
        team1: "Руководитель команды основателей по программным технологиям из Alibaba Group. Когда-то он работал на уровне Alibaba P9 и разработал около 150+ наборов систем для торговых центров электронной коммерции, систем SAAS для торговых центров, систем отслеживания блокчейнов, систем обмена блокчейнов и т. д.",
        team2: "Мы стремимся предоставлять клиентам полный спектр индивидуальных услуг по разработке программного обеспечения, чтобы помочь им добиться дифференцированной конкуренции продуктов и устойчивого роста рынка. Мы верим, что благодаря нашим усилиям и инновациям мы сможем предоставить больше возможностей и возможностей индустрии товаров для взрослых.",
    },
    contact: {
        title: "связаться с нами",
        contact1: "Контактное лицо: г-н Пэн",
        contact2: "Контакт: +(86) 13510415119",
        contact3: "Контактный адрес электронной почты: global.nantasia@gmail.com",
        contact4: "Адрес: Комната 216, 2-й этаж, корпус А, фаза 2, город Шанхай Юньхуэй, № 1171 Сюэган-роуд, община Сянцзяотан, улица Бантянь, район Лунган, город Шэньчжэнь, провинция Гуандун (выход b из станции метро Gangtou)",
        service: "WeChat"
    },
    footer: {
        title: "无Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Все права защищены."
    }

};
