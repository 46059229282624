export default {
    home:{
        navs: {
            product: "produit",
            about: "à propos de nous",
            contact: "Contactez-nous",
        },
        article: {
            policy: "politique",
            and: "et",
            agreement: "protocole",
        }
    },
    download: "télécharger",
    app: {
        title: "App intelligente de produits pour adultes",
        content: "Personnalisation personnalisée, expériences diverses",
    },
    data: {
        title: "L'application Nantasia Adult Products est notre produit phare et a été mise sur l'Apple Store et Google Play.",
        dataOne: "Client",
        dataTwo: "Pays et régions couverts",
        dataThree: "Langage d'adaptation",
    },
    func: {
        title: "Module de fonction d'application",
        mode1: "Mode classique",
        mode2: "interaction à distance",
        mode3: "mode musique",
        mode4: "mode vidéo",
        mode5: "mode course",
    },
    service: {
        title: "Module de fonctionnement de l'application",
        mode1: "Affichage publicitaire exclusif",
        mode2: "Recommandations exclusives de nouveaux produits",
        mode3: "Système de service client exclusif",
        mode4: "Personnalisation exclusive de l'interface utilisateur",
        mode5: "Personnalisation exclusive du gameplay",
    },
    manage: {
        title: "Système de gestion des produits",
        t1: "Système de gestion des produits",
        t2: "Système de gestion du service client",
        t3: "Système de gestion des membres",
        t4: "Système de gestion de publicité",
        c1: "Gérez les produits en un seul clic",
        c2: "Compréhension individuelle en temps réel des besoins des clients",
        c3: "Capturez les membres cibles en temps réel",
        c4: "Plus de colonnes publicitaires s'affichent",
        note1: "Nantasia fournit un système de gestion backend indépendant pour chaque commerçant coopératif.",
        note2: "Les commerçants ont le droit de fonctionner de manière indépendante et de faire de leur mieux pour répondre aux besoins opérationnels de chaque commerçant partenaire.",
    },
    about: {
        title: "à propos de nous",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. est une entreprise de recherche et développement technologique axée sur les solutions Bluetooth pour les produits pour adultes.",
        team1: "Le leader de la technologie logicielle de l'équipe fondatrice appartient au groupe Alibaba. Il a déjà travaillé au niveau Alibaba P9 et a développé près de 150 ensembles de systèmes de centres commerciaux de commerce électronique, de systèmes SAAS de centres commerciaux, de systèmes de traçabilité blockchain, de systèmes d'échange blockchain, etc.",
        team2: "Nous nous engageons à fournir à nos clients une gamme complète de services de développement de logiciels personnalisés pour les aider à parvenir à une concurrence différenciée en matière de produits et à une croissance soutenue du marché. Nous pensons que grâce à nos efforts et à notre innovation, nous pouvons apporter davantage de possibilités et d'opportunités à l'industrie des produits pour adultes.",
    },
    contact: {
        title: "Contactez-nous",
        contact1: "Personne à contacter: M. Peng",
        contact2: "Contact: +(86) 13510415119",
        contact3: "E-mail de contact: global.nantasia@gmail.com",
        contact4: "Adresse : salle 216, 2ème étage, bâtiment A, phase 2, ville de Shanhai Yunhui, n° 1171 Xuegang Road, communauté Xiangjiaotang, rue Bantian, district de Longgang, ville de Shenzhen, province du Guangdong (sortie b de la station de métro Gangtou)",
        service: "Service client WeChat"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Tous droits réservés"
    }

};
