export default {
    home:{
        navs: {
            product: "製品",
            about: "当社について",
            contact: "お問い合わせ",
        },
        article: {
            policy: "プライバシーポリシー",
            and: "そして",
            agreement: "ユーザー規約",
        }
    },
    download: "得る",
    app: {
        title: "インテリジェントなNantasiaアダルト製品APP",
        content: "パーソナライズされたカスタマイズで多様な製品エクスペリエンスを生み出す",
    },
    data: {
        title: "Nantasia Adult Products App は当社の主力製品であり、Apple Store と Google Play に掲載されています。",
        dataOne: "クライアント",
        dataTwo: "対象となる国と地域",
        dataThree: "アプリは各国語に適応します",
    },
    func: {
        title: "アプリ機能モジュール",
        mode1: "クラシックモード",
        mode2: "リモートインタラクション",
        mode3: "音楽モード",
        mode4: "ビデオモード",
        mode5: "ストロークモード",
    },
    service: {
        title: "アプリ操作モジュール",
        mode1: "加盟店限定の広告表示",
        mode2: "独占的な新製品の推奨事項",
        mode3: "加盟店様専用のカスタマーサービスシステム",
        mode4: "販売者専用の UI カスタマイズ",
        mode5: "販売者専用のゲームプレイのカスタマイズ",
    },
    manage: {
        title: "アプリ販売者管理バックエンド",
        t1: "商品管理システム",
        t2: "顧客サービス管理システム",
        t3: "会員管理システム",
        t4: "広告管理システム",
        c1: "ワンクリックで製品を管理",
        c2: "顧客のニーズをリアルタイムで 1 対 1 で理解",
        c3: "ターゲットメンバーをリアルタイムに捕捉",
        c4: "さらに多くの広告列を表示",
        note1: "Nantasia は、すべての協同組合加盟店に独立したバックエンド管理システムを提供します。",
        note2: "加盟店は独立して運営し、各パートナー加盟店の運営上のニーズを満たすために最善を尽くす権利を有します。",
    },
    about: {
        title: "私たちについて",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. は、成人向け製品向けの Bluetooth ソリューションに焦点を当てた技術研究開発企業です。",
        team1: "創設者チームのソフトウェア技術リーダーはアリババグループ出身で、かつてアリババP9レベルで勤務し、150セット近くの電子商取引モールシステム、モールSAASシステム、ブロックチェーントレーサビリティシステム、ブロックチェーン交換システムなどを開発してきました。",
        team2: "当社は、顧客が差別化された製品競争と持続的な市場成長を達成できるよう、あらゆる種類のカスタマイズされたソフトウェア開発サービスを顧客に提供することに尽力しています。 私たちは、私たちの努力と革新を通じて、アダルト製品業界により多くの可能性と機会をもたらすことができると信じています。",
    },
    contact: {
        title: "お問い合わせ",
        contact1: "連絡担当者: 彭氏",
        contact2: "連絡先: +(86) 13510415119",
        contact3: "連絡先メールアドレス: global.nantasia@gmail.com",
        contact4: "住所：広東省深セン市龍崗区盤田街、Xiangangang Road、Xiangjiaotang Community、Xuegang Road 1171、City Shanhai Yunhui、Phase 2、216号室（地下鉄港頭駅b出口）",
        service: "WeChatカスタマーサービス"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. 無断複写・転載を禁じます"
    }

};
