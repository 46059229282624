export default {
    home:{
        navs: {
            product: "Produkt",
            about: "über uns",
            contact: "kontaktiere uns",
        },
        article: {
            policy: "Politik",
            and: "Und",
            agreement: "Protokoll",
        }
    },
    download: "erhalten",
    app: {
        title: "Intelligente App für Erwachsenenprodukte",
        content: "Personalisierte Anpassung, vielfältige Erlebnisse",
    },
    data: {
        title: "Die Nantasia Adult Products App ist unser Flaggschiffprodukt und wurde im Apple Store und bei Google Play veröffentlicht",
        dataOne: "Klient",
        dataTwo: "Abgedeckte Länder und Regionen",
        dataThree: "Anpassungssprache",
    },
    func: {
        title: "App-Funktionsmodul",
        mode1: "Klassischer Modus",
        mode2: "Ferninteraktion",
        mode3: "Musikmodus",
        mode4: "Video Modus",
        mode5: "Schlagmodus",
    },
    service: {
        title: "App-Bedienmodul",
        mode1: "Exklusiver Werbeaufsteller",
        mode2: "Exklusive neue Produktempfehlungen",
        mode3: "Exklusives Kundendienstsystem",
        mode4: "Exklusive UI-Anpassung",
        mode5: "Exklusive Gameplay-Anpassung",
    },
    manage: {
        title: "Backend für die App-Händlerverwaltung",
        t1: "Warenwirtschaftssystem",
        t2: "Kundenservice-Managementsystem",
        t3: "Mitgliederverwaltungssystem",
        t4: "Werbemanagementsystem",
        c1: "Verwalten Sie Produkte mit einem Klick",
        c2: "Eins-zu-eins-Verständnis der Kundenbedürfnisse in Echtzeit",
        c3: "Erfassen Sie Zielmitglieder in Echtzeit",
        c4: "Weitere Litfaßsäulen werden angezeigt",
        note1: "Nantasia bietet jedem kooperativen Händler ein unabhängiges Backend-Managementsystem.",
        note2: "Händler haben das Recht, unabhängig zu agieren und ihr Bestes zu geben, um die betrieblichen Anforderungen jedes Partnerhändlers zu erfüllen.",
    },
    about: {
        title: "über uns",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. ist ein Technologieforschungs- und Entwicklungsunternehmen, das sich auf Bluetooth-Lösungen für Produkte für Erwachsene konzentriert.",
        team1: "Der Software-Technologieleiter des Gründerteams stammt von der Alibaba Group. Er arbeitete einst auf Alibaba P9-Ebene und hat mehr als 150 Sätze von E-Commerce-Einkaufszentrumssystemen, Einkaufszentrum-SAAS-Systemen, Blockchain-Rückverfolgbarkeitssystemen, Blockchain-Austauschsystemen usw. entwickelt.",
        team2: "Wir sind bestrebt, unseren Kunden ein umfassendes Spektrum an maßgeschneiderten Softwareentwicklungsdiensten anzubieten, um ihnen zu einem differenzierten Produktwettbewerb und nachhaltigem Marktwachstum zu verhelfen. Wir glauben, dass wir durch unsere Bemühungen und Innovationen mehr Möglichkeiten und Möglichkeiten für die Erotikproduktbranche schaffen können.",
    },
    contact: {
        title: "kontaktiere uns",
        contact1: "Ansprechpartner: Herr Peng",
        contact2: "Kontakt: +(86) 13510415119",
        contact3: "Kontakt-E-Mail: global.nantasia@gmail.com",
        contact4: "Adresse: Raum 216, 2. Etage, Gebäude A, Phase 2, Stadt Shanhai Yunhui, Nr. 1171 Xuegang Road, Gemeinde Xiangjiaotang, Bantian Street, Bezirk Longgang, Stadt Shenzhen, Provinz Guangdong (Ausgang B der U-Bahn-Station Gangtou)",
        service: "WeChat-Kundenservice"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Alle Rechte vorbehalten"
    }

};
