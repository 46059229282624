export default {
    home:{
        navs: {
            product: "Prodotto",
            about: "chi siamo",
            contact: "Contattaci",
        },
        article: {
            policy: "politica",
            and: "E",
            agreement: "protocollo",
        }
    },
    download: "Ottenere",
    app: {
        title: "APP intelligente per prodotti per adulti Nantasia",
        content: "Personalizzazione personalizzata, esperienze diverse",
    },
    data: {
        title: "L'app Nantasia Adult Products è il nostro prodotto di punta ed è stata inserita su Apple Store e Google Play",
        dataOne: "Cliente",
        dataTwo: "Paesi e regioni coperti",
        dataThree: "L'app si adatta alla lingua nazionale",
    },
    func: {
        title: "Modulo funzione dell'app",
        mode1: "Modalità classica",
        mode2: "interazione remota",
        mode3: "modalità musica",
        mode4: "modalità video",
        mode5: "modalità corsa",
    },
    service: {
        title: "Modulo operativo dell'app",
        mode1: "Espositore pubblicitario esclusivo",
        mode2: "Consigli esclusivi su nuovi prodotti",
        mode3: "Sistema esclusivo di assistenza clienti",
        mode4: "Personalizzazione esclusiva dell'interfaccia utente",
        mode5: "Personalizzazione esclusiva del gameplay",
    },
    manage: {
        title: "Backend di gestione del commerciante dell'app",
        t1: "Sistema di gestione delle merci",
        t2: "Sistema di gestione del servizio clienti",
        t3: "Sistema di gestione dei membri",
        t4: "Sistema di gestione della pubblicità",
        c1: "Gestisci i prodotti con un clic",
        c2: "Comprensione individuale in tempo reale delle esigenze dei clienti",
        c3: "Cattura i membri target in tempo reale",
        c4: "Vengono visualizzate più colonne pubblicitarie",
        note1: "Nantasia fornisce un sistema di gestione backend indipendente per ogni commerciante cooperativo.",
        note2: "I commercianti hanno il diritto di operare in modo indipendente e fare del loro meglio per soddisfare le esigenze operative di ciascun commerciante partner.",
    },
    about: {
        title: "chi siamo",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. è un'impresa di ricerca e sviluppo tecnologico focalizzata su soluzioni Bluetooth per prodotti per adulti.",
        team1: "Il leader della tecnologia software del team fondatore proviene da Alibaba Group. Una volta ha lavorato al livello Alibaba P9 e ha sviluppato quasi 150+ set di sistemi di centri commerciali e-commerce, sistemi SAAS per centri commerciali, sistemi di tracciabilità blockchain, sistemi di scambio blockchain, ecc.",
        team2: "Ci impegniamo a fornire ai clienti una gamma completa di servizi di sviluppo software personalizzati per aiutarli a ottenere una concorrenza di prodotto differenziata e una crescita sostenuta del mercato. Crediamo che attraverso i nostri sforzi e la nostra innovazione possiamo offrire maggiori possibilità e opportunità al settore dei prodotti per adulti.",
    },
    contact: {
        title: "Contattaci",
        contact1: "Persona di contatto: Sig. Peng",
        contact2: "Contatto: +(86) 13510415119",
        contact3: "E-mail di contatto: global.nantasia@gmail.com",
        contact4: "Indirizzo: Sala 216, 2° piano, Edificio A, Fase 2, Città Shanhai Yunhui, No. 1171 Xuegang Road, Comunità Xiangjiaotang, Bantian Street, Distretto Longgang, Città di Shenzhen, Provincia di Guangdong (Uscita b della stazione della metropolitana Gangtou)",
        service: "Servizio clienti WeChat"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Tutti i diritti riservati"
    }

};
