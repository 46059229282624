import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from './lang/index'

Vue.config.productionTip = false;
Vue.prototype.getQueryString = (name) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
