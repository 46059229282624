<template>
  <div class="home">
    <a id="home" style="position: relative; top: -80px"></a>
    <div class="header" :class="top > 80? 'shadow': ''" v-if="isPc || showHeader" @click="showHeader = false">
      <div class="center" @click.stop="">
        <div class="logo">
          <img src="../assets/logo.png" alt="" />
          <span class="line"></span>
          <img src="../assets/nantasia.png"  class="tiny_logo" alt="" />
        </div>
        <div class="nav">
          <a class="item" href="#home" :class="selIndex === 1?'sel': ''"  @click="sel(1)">{{$t("home.navs.product")}}</a>
          <a class="item" href="#about" :class="selIndex === 2?'sel': ''"  @click="sel(2)">{{$t("home.navs.about")}}</a>
          <a class="item" href="#contact" :class="selIndex === 3?'sel': ''"  @click="sel(3)">{{$t("home.navs.contact")}}</a>
        </div>
        <div class="article">
          <router-link to="/article?id=1" class="link">{{$t("home.article.agreement")}}</router-link>
          <span>{{$t("home.article.and")}}</span>
          <router-link to="/article?id=2" class="link">{{$t("home.article.policy")}}</router-link>
        </div>
        <div class="download_btn" @click.stop="download">{{$t("download")}}</div>
        <div class="language_box" @click.stop="switchLang">
          <span>{{lang[$i18n.locale || 'en']}}</span>
          <img class="arrow" src="../assets/down.png" alt="" v-if="!hoverLang" />
          <img class="arrow" src="../assets/up.png" alt="" v-if="hoverLang" />
          <img class="global" src="../assets/global.png" alt=""/>
          <div class="language_content" v-if="hoverLang">
            <span @click.stop="changeLang(key)" v-for="(value, key) in lang" :key="key">{{value}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section" :class="top > 80? 'shadow': ''">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
        <span class="line"></span>
        <img src="../assets/nantasia.png"  class="tiny_logo" alt="" />
      </div>
      <img class="open" src="../assets/select.png" alt="" @click="showHeader = true" />
    </div>
    <div class="download_wrapper">
      <div class="title">{{$t("app.title")}}</div>
      <div class="content">{{$t("app.content")}}</div>
      <div class="btn" @click="download">{{$t("download")}}</div>
      <img src="../assets/app.png" alt="" />
    </div>
    <div class="data">
      <img src="../assets/nantasia_txt.png" alt="" />
      <div class="brief">{{$t("data.title")}}</div>
      <div class="data_box">
        <div class="item">
          <div class="content">1200000</div>
          <div class="title">{{$t("data.dataOne")}}</div>
        </div>
        <div class="item">
          <div class="content">20+</div>
          <div class="title">{{$t("data.dataTwo")}}</div>
        </div>
        <div class="item">
          <div class="content">11</div>
          <div class="title">{{$t("data.dataThree")}}</div>
        </div>
      </div>
    </div>
    <div class="func">
      <div class="title">{{$t("func.title")}}</div>
      <div class="center">
        <div class="item">
          <img src="../assets/play.png" alt="" />
          <div class="name">{{$t("func.mode1")}}</div>
        </div>
        <div class="item">
          <img src="../assets/heart.png" alt="" />
          <div class="name">{{$t("func.mode2")}}</div>
        </div>
        <div class="item">
          <img src="../assets/music.png" alt="" />
          <div class="name">{{$t("func.mode3")}}</div>
        </div>
        <div class="item">
          <img src="../assets/video.png" alt="" />
          <div class="name">{{$t("func.mode4")}}</div>
        </div>
        <div class="item">
          <img src="../assets/draw.png" alt="" />
          <div class="name">{{$t("func.mode5")}}</div>
        </div>
      </div>
    </div>
    <div class="func service">
      <div class="title">{{$t("service.title")}}</div>
      <div class="center">
        <div class="item">
          <img src="../assets/merchant1.png" alt="" />
          <div class="name">{{$t("service.mode1")}}</div>
        </div>
        <div class="item">
          <img src="../assets/merchant2.png" alt="" />
          <div class="name">{{$t("service.mode2")}}</div>
        </div>
        <div class="item">
          <img src="../assets/merchant3.png" alt="" />
          <div class="name">{{$t("service.mode3")}}</div>
        </div>
        <div class="item">
          <img src="../assets/merchant4.png" alt="" />
          <div class="name">{{$t("service.mode4")}}</div>
        </div>
        <div class="item">
          <img src="../assets/merchant5.png" alt="" />
          <div class="name">{{$t("service.mode5")}}</div>
        </div>
      </div>
    </div>
    <div class="func manage">
      <div class="title">{{$t("manage.title")}}</div>
      <div class="center">
        <div class="item">
          <img src="../assets/1.png" alt="" />
          <div class="intro">
            <div class="t">{{$t("manage.t1")}}</div>
            <div class="c">{{$t("manage.c1")}}</div>
          </div>
        </div>
        <div class="item">
          <img src="../assets/2.png" alt="" />
          <div class="intro">
            <div class="t">{{$t("manage.t2")}}</div>
            <div class="c">{{$t("manage.c2")}}</div>
          </div>
        </div>
        <div class="item">
          <img src="../assets/3.png" alt="" />
          <div class="intro">
            <div class="t">{{$t("manage.t3")}}</div>
            <div class="c">{{$t("manage.c3")}}</div>
          </div>
        </div>
        <div class="item">
          <img src="../assets/4.png" alt="" />
          <div class="intro">
            <div class="t">{{$t("manage.t4")}}</div>
            <div class="c">{{$t("manage.c4")}}</div>
          </div>
        </div>
        <div class="note">
          <p>{{$t("manage.note1")}}</p>
          <p>{{$t("manage.note2")}}</p>
        </div>
      </div>
    </div>
    <a id="about" style="position: relative; top: -80px"></a>
    <div class="func about">
      <div class="title">{{$t("about.title")}}</div>
      <div class="content">{{$t("about.content")}}</div>
      <div class="center">
        <div class="leap">
          <img src="../assets/about1.png" alt="" />
          <div class="introduction">{{$t("about.team1")}}</div>
        </div>
        <div class="connect">
          <img class="link" src="../assets/link.png" alt="" />
        </div>
        <div class="leap">
          <img src="../assets/about2.png" alt="" />
          <div class="introduction">{{$t("about.team2")}}</div>
        </div>
      </div>
    </div>
    <a id="contact" style="position: relative; top: -80px"></a>
    <div class="func contact">
      <div class="title">{{$t("contact.title")}}</div>
      <div class="center">
        <div class="contact_box">
          <p>{{$t("contact.contact1")}}</p>
          <p>{{$t("contact.contact2")}}</p>
          <p>{{$t("contact.contact3")}}</p>
          <p>{{$t("contact.contact4")}}</p>
        </div>
        <div class="qrcode">
          <div class="code_text">{{$t("contact.service")}}</div>
          <img src="../assets/wechat.png" alt="" />
        </div>
      </div>
    </div>
    <div class="footer">
      <p>&copy;2023 {{$t("footer.title")}} <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023118498号</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data(){
    return {
      isPc: true,
      showHeader: false,
      hoverLang: false,
      selIndex: 1,
      top: 0,
      lang: {
        zh: "简体中文",
        en: "English",
        de: "Deutsch",
        fr: "Français",
        py: "Русский язык",
        es: "Español",
        jp: "日本語",
        kor: "한국어",
        al: "بالعربية",
        it: "Italiano",
        por: "Português",
      }
    }
  },
  mounted() {
    this.renderClient();
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.renderClient)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.renderClient)
  },
  methods: {
    renderClient(){
      let winWidth;
      if (window.innerWidth) {
        winWidth = window.innerWidth;
      }else if ((document.body) && (document.body.clientWidth)){
        winWidth = document.body.clientWidth;
      }
      this.isPc = winWidth >= 1200;
    },
    handleScroll() {
      this.top = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
    },
    switchLang(){
      this.hoverLang = !this.hoverLang;
    },
    sel(index){
      this.selIndex = index;
      this.showHeader = false;
    },
    changeLang(lang){
      localStorage.setItem("lang", lang);
      this.hoverLang = false;
      this.$i18n.locale = lang;
    },
    download(){
      window.open("https://m.nantasia.vip/pages/download/app");
    }
  }
};
</script>
<style lang="scss" scoped src="../styles/home.scss"></style>
<style lang="scss" scoped src="../styles/home-h5.scss"></style>
