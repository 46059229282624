export default {
    home:{
        navs: {
            product: "producto",
            about: "sobre nosotros",
            contact: "Contáctenos",
        },
        article: {
            policy: "política",
            and: "y",
            agreement: "protocolo",
        }
    },
    download: "descargar",
    app: {
        title: "APP de productos inteligentes para adultos",
        content: "Personalización personalizada, experiencias diversas.",
    },
    data: {
        title: "La aplicación Nantasia Adult Products es nuestro producto estrella y se ha incluido en Apple Store y Google Play.",
        dataOne: "Cliente",
        dataTwo: "Países y regiones cubiertos",
        dataThree: "Lenguaje de adaptación",
    },
    func: {
        title: "Módulo de funciones de la aplicación",
        mode1: "Modo clásico",
        mode2: "interacción remota",
        mode3: "modo de música",
        mode4: "modo de vídeo",
        mode5: "modo de trazo",
    },
    service: {
        title: "Módulo de operación de la aplicación",
        mode1: "Expositor publicitario exclusivo.",
        mode2: "Recomendaciones exclusivas de nuevos productos.",
        mode3: "Sistema exclusivo de atención al cliente.",
        mode4: "Personalización exclusiva de la interfaz de usuario",
        mode5: "Personalización exclusiva del juego.",
    },
    manage: {
        title: "Backend de gestión de comerciantes de aplicaciones",
        t1: "Sistema de gestión de productos básicos.",
        t2: "Sistema de gestión de atención al cliente.",
        t3: "Sistema de gestión de miembros",
        t4: "Sistema de gestión de publicidad.",
        c1: "Gestiona productos con un clic",
        c2: "Comprensión personalizada en tiempo real de las necesidades del cliente",
        c3: "Capture miembros objetivo en tiempo real",
        c4: "Se muestran más columnas publicitarias.",
        note1: "Nantasia proporciona un sistema de gestión backend independiente para cada comerciante cooperativo.",
        note2: "Los comerciantes tienen derecho a operar de forma independiente y hacer todo lo posible para satisfacer las necesidades operativas de cada comerciante asociado.",
    },
    about: {
        title: "sobre nosotros",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. es una empresa de investigación y desarrollo de tecnología que se centra en soluciones Bluetooth para productos para adultos.",
        team1: "El líder de tecnología de software del equipo fundador es del Grupo Alibaba. Una vez trabajó en el nivel Alibaba P9 y ha desarrollado casi 150 conjuntos de sistemas de centros comerciales de comercio electrónico, sistemas SAAS de centros comerciales, sistemas de trazabilidad de blockchain, sistemas de intercambio de blockchain, etc.",
        team2: "Estamos comprometidos a brindar a los clientes una gama completa de servicios de desarrollo de software personalizados para ayudarlos a lograr una competencia de productos diferenciada y un crecimiento sostenido del mercado. Creemos que a través de nuestros esfuerzos e innovación, podemos brindar más posibilidades y oportunidades a la industria de productos para adultos.",
    },
    contact: {
        title: "Contáctenos",
        contact1: "Persona de contacto: Sr. Peng",
        contact2: "Contacto: +(86) 13510415119",
        contact3: "Correo electrónico de contacto: global.nantasia@gmail.com",
        contact4: "Dirección: Sala 216, 2do piso, Edificio A, Fase 2, Ciudad Shanhai Yunhui, No. 1171 Xuegang Road, Comunidad Xiangjiaotang, Calle Bantian, Distrito de Longgang, Ciudad de Shenzhen, Provincia de Guangdong (Salida b de la estación de metro Gangtou)",
        service: "Servicio al cliente WeChat"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. Todos los derechos reservados"
    }

};
