export default {
    home:{
        navs: {
            product: "제품",
            about: "회사 소개",
            contact: "문의하기",
        },
        article: {
            policy: "개인 정보 정책",
            and: "그리고",
            agreement: "사용자 계약",
        }
    },
    download: "다운로드",
    app: {
        title: "지능형 Nantasia 성인용품 APP",
        content: "다양한 제품 경험을 창출하는 개인화된 맞춤화",
    },
    data: {
        title: "Nantasia Adult Products 앱은 당사의 주력 제품이며 Apple Store 및 Google Play에 출시되었습니다.",
        dataOne: "고객",
        dataTwo: "해당 국가 및 지역",
        dataThree: "앱이 자국어에 맞춰집니다.",
    },
    func: {
        title: "앱 기능 모듈",
        mode1: "클래식 모드",
        mode2: "원격 상호작용",
        mode3: "음악 모드",
        mode4: "비디오 모드",
        mode5: "스트로크 모드",
    },
    service: {
        title: "앱 운영 모듈",
        mode1: "독점 광고 디스플레이",
        mode2: "독점 신제품 추천",
        mode3: "독점적인 고객 서비스 시스템",
        mode4: "독점적인 UI 사용자 정의",
        mode5: "독점적인 게임플레이 사용자 정의",
    },
    manage: {
        title: "앱 판매자 관리 백엔드",
        t1: "상품 관리 시스템",
        t2: "고객 서비스 관리 시스템",
        t3: "회원관리 시스템",
        t4: "광고 관리 시스템",
        c1: "클릭 한 번으로 제품 관리",
        c2: "고객의 요구사항을 실시간 1:1로 파악",
        c3: "타겟 멤버를 실시간으로 포착",
        c4: "더 많은 광고 항목 표시",
        note1: "Nantasia는 모든 협력 판매자에게 독립적인 백엔드 관리 시스템을 제공합니다.",
        note2: "판매자는 독립적으로 운영할 권리가 있으며 각 파트너 판매자의 운영 요구 사항을 충족하기 위해 최선을 다할 권리가 있습니다.",
    },
    about: {
        title: "회사 소개",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd.는 성인용 블루투스 솔루션에 중점을 둔 기술 연구 개발 기업입니다.",
        team1: "설립자 팀의 소프트웨어 기술 리더는 Alibaba 그룹 출신으로, 한때 Alibaba P9 레벨에서 근무했으며 약 150개 이상의 전자상거래 쇼핑몰 시스템, 쇼핑몰 SAAS 시스템, 블록체인 추적 시스템, 블록체인 교환 시스템 등을 개발했습니다.",
        team2: "우리는 고객에게 차별화된 제품 경쟁과 지속적인 시장 성장을 달성할 수 있도록 광범위한 맞춤형 소프트웨어 개발 서비스를 제공하기 위해 최선을 다하고 있습니다. 우리는 우리의 노력과 혁신을 통해 성인용품 산업에 더 많은 가능성과 기회를 가져올 수 있다고 믿습니다.",
    },
    contact: {
        title: "문의하기",
        contact1: "담당자: Mr. Peng",
        contact2: "연락처: +(86) 13510415119",
        contact3: "이메일 문의: global.nantasia@gmail.com",
        contact4: "주소: 광둥성 심천시 룽강구 반티엔 거리 Xuegang Road, Xiangjiaotang Community 1171호 City Shanhai Yunhui 2단계 A동 2층 216호(강터우 지하철역 b 출구)",
        service: "위챗 고객 서비스"
    },
    footer: {
        title: "무한 창조 (심천) 기술 홀딩스 유한 회사 판권 소유"
    }

};
