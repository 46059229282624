export default {
    home:{
        navs: {
            product: "公司产品",
            about: "关于我们",
            contact: "联系我们",
        },
        article: {
            policy: "隐私政策",
            and: "和",
            agreement: "用户协议",
        }
    },
    download: "立即下载",
    app: {
        title: "智能化Nantasia成人用品APP",
        content: "个性化定制，打造多样化用品体验",
    },
    data: {
        title: "Nantasia成人用品App是我们的主打产品，已上架Apple Store及Google Play",
        dataOne: "用户群体",
        dataTwo: "覆盖国家和地区",
        dataThree: "App适配国家语言",
    },
    func: {
        title: "App功能模块",
        mode1: "经典模式",
        mode2: "远程互动",
        mode3: "音乐模式",
        mode4: "视频模式",
        mode5: "划一划模式",
    },
    service: {
        title: "App运营模块",
        mode1: "商家专属广告展示",
        mode2: "商家专属新品推荐",
        mode3: "商家专属客服系统",
        mode4: "商家专属UI定制",
        mode5: "商家专属玩法定制",
    },
    manage: {
        title: "App商家管理后台",
        t1: "商品管理系统",
        t2: "客服管理系统",
        t3: "会员管理系统",
        t4: "广告管理系统",
        c1: "一键管理商品",
        c2: "实时一对一掌握客户需求",
        c3: "目标会员实时抓取",
        c4: "更多广告栏目展示",
        note1: "Nantasia为每一位合作的商家，提供独立的后台管理系统。",
        note2: "商家拥有独立自主运营权，尽可能满足每一位合作商家的运营需求。",
    },
    about: {
        title: "关于我们",
        content: "无限创想（深圳）科技控股有限公司是一家专注于成人用品蓝牙解决方案科技研发型企业。",
        team1: "创始人团队软件技术负责人来自阿里集团，曾任职阿里P9级别，累计开发近150+套电商商城系统、商城SAAS系统、区块链溯源系统、区块链交易所系统等。",
        team2: "我们致力于为客户提供全方位的软件定制开发服务，帮助他们实现产品的差异化竞争和市场的持续增长。我们相信，通过我们的努力和创新，能够为成人用品行业带来更多的可能性和机遇。",
    },
    contact: {
        title: "联系我们",
        contact1: "联系人：彭先生",
        contact2: "联系方式：+(86) 13510415119",
        contact3: "联系邮箱：global.nantasia@gmail.com",
        contact4: "地址：广东省深圳市龙岗区坂田街道象角塘社区雪岗路1171号城市山海云汇二期A栋二层216(岗头地铁站b出口)",
        service: "微信客服"
    },
    footer: {
        title: "无限创想（深圳）科技控股有限公司 版权所有"
    }

};
