export default {
    home:{
        navs: {
            product: "Product",
            about: "About Us",
            contact: "Contact Us",
        },
        article: {
            policy: "Privacy Policy",
            and: "and",
            agreement: "User Agreement",
        }
    },
    download: "Download",
    app: {
        title: "Smart Nantasia Adult Products APP",
        content: "Personalized customization to create a diverse product experience",
    },
    data: {
        title: "Nantasia Adult Products App is our flagship product and has been put on the Apple Store and Google Play",
        dataOne: "Users",
        dataTwo: "Countries and Regions",
        dataThree: "App Languages",
    },
    func: {
        title: "App Function Module",
        mode1: "Classic Mode",
        mode2: "Remote Interaction",
        mode3: "Music Mode",
        mode4: "Video Mode",
        mode5: "swipe Mode",
    },
    service: {
        title: "App Operation Module",
        mode1: "Merchant exclusive advertising display",
        mode2: "Merchant exclusive new product recommendation",
        mode3: "Merchant exclusive customer service system",
        mode4: "Merchant exclusive UI customization",
        mode5: "Merchant exclusive gameplay customization",
    },
    manage: {
        title: "App Merchant Management Backend",
        t1: "Commodity Management System",
        t2: "Customer Service Management System",
        t3: "Member Management System",
        t4: "Advertising Management System",
        c1: "Manage products with one click",
        c2: "Real-time one-to-one understanding of customer needs",
        c3: "Real-time capture of target members",
        c4: "More advertising columns display",
        note1: "Nantasia provides an independent backend management system for each merchant it cooperates with.",
        note2: "Merchants have the right to operate independently and try their best to meet the operational needs of each partner merchant.",
    },
    about: {
        title: "About Us",
        content: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. is a technology research and development enterprise focusing on Bluetooth solutions for adult products.",
        team1: "The software technology leader of the founder team is from Alibaba Group. He has worked at Alibaba P9 level and has developed nearly 150+ e-commerce mall systems, mall SAAS systems, blockchain traceability systems, blockchain exchange systems, etc." ,
        team2: "We are committed to providing customers with a full range of customized software development services to help them achieve product differentiation and sustainable market growth. We believe that through our efforts and innovation, we can bring more to the adult products industry possibilities and opportunities.",
    },
    contact: {
        title: "Contact Us",
        contact1: "Contact person: Mr. Peng",
        contact2: "Contact information: +(86) 13510415119",
        contact3: "Contact email: global.nantasia@gmail.com",
        contact4: "Address: Room 216, 2nd Floor, Building A, Phase 2, City Shanhai Yunhui, No. 1171, Xuegang Road, Xiangjiaotang Community, Bantian Street, Longgang District, Shenzhen City, Guangdong Province (Exit b of Gangtou Metro Station)",
        service: "WeChat"
    },
    footer: {
        title: "Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. All rights reserved"
    }

};
