export default {
    home:{
        navs: {
            product: "منتج",
            about: "معلومات عنا",
            contact: "اتصل بنا",
        },
        article: {
            policy: "سياسة الخصوصية",
            and: "و",
            agreement: "اتفاقية المستخدم",
        }
    },
    download: "يحصل",
    app: {
        title: "ذكي منتجات نانتازيا الكبار APP",
        content: "التخصيص الشخصي لخلق تجربة منتج متنوعة",
    },
    data: {
        title: "يعد تطبيق منتجات البالغين منتجنا الرئيسي وقد تم طرحه على متجري Apple Store وGoogle Play",
        dataOne: "عميل",
        dataTwo: "البلدان والمناطق المشمولة",
        dataThree: "التطبيق يتكيف مع اللغة الوطنية",
    },
    func: {
        title: "وحدة وظيفة التطبيق",
        mode1: "الوضع الكلاسيكي",
        mode2: "التفاعل عن بعد",
        mode3: "وضع الموسيقى",
        mode4: "وضعية الفيديو",
        mode5: "وضع السكتة الدماغية",
    },
    service: {
        title: "وحدة تشغيل التطبيق",
        mode1: "عرض الإعلانات الحصرية للتاجر",
        mode2: "توصيات المنتجات الجديدة الحصرية",
        mode3: "نظام خدمة العملاء الحصري",
        mode4: "تخصيص واجهة المستخدم الحصرية",
        mode5: "تخصيص اللعب الحصري",
    },
    manage: {
        title: "الواجهة الخلفية لإدارة تاجر التطبيق",
        t1: "نظام إدارة السلع",
        t2: "نظام إدارة خدمة العملاء",
        t3: "نظام إدارة الأعضاء",
        t4: "نظام إدارة الإعلانات",
        c1: "إدارة المنتجات بنقرة واحدة",
        c2: "فهم فردي لاحتياجات العملاء في الوقت الفعلي",
        c3: "التقاط الأعضاء المستهدفين في الوقت الحقيقي",
        c4: "يتم عرض المزيد من الأعمدة الإعلانية",
        note1: "توفر Nantasia نظام إدارة خلفي مستقل لكل تاجر متعاون.",
        note2: "يحق للتجار العمل بشكل مستقل وبذل قصارى جهدهم لتلبية الاحتياجات التشغيلية لكل تاجر شريك.",
    },
    about: {
        title: "معلومات عنا",
        content: "شركة Infinite Creation (Shenzhen) Technology Holdings Co., Ltd. هي مؤسسة بحث وتطوير تكنولوجي تركز على حلول Bluetooth لمنتجات البالغين.",
        team1: "قائد تكنولوجيا البرمجيات للفريق المؤسس هو من مجموعة علي بابا، وقد عمل سابقًا على مستوى علي بابا P9 وقام بتطوير ما يقرب من 150 مجموعة من أنظمة مراكز التجارة الإلكترونية، وأنظمة مراكز التسوق SAAS، وأنظمة تتبع blockchain، وأنظمة تبادل blockchain، وما إلى ذلك.",
        team2: "نحن ملتزمون بتزويد العملاء بمجموعة كاملة من خدمات تطوير البرمجيات المخصصة لمساعدتهم على تحقيق منافسة منتجات مختلفة ونمو مستدام في السوق. نحن نؤمن أنه من خلال جهودنا وابتكارنا، يمكننا جلب المزيد من الإمكانيات والفرص لصناعة المنتجات للبالغين.",
    },
    contact: {
        title: "اتصل بنا",
        contact1: "شخص الاتصال: السيد بنغ",
        contact2: "الاتصال: +(86) 13510415119",
        contact3: "البريد الإلكتروني للاتصال: global.nantasia@gmail.com",
        contact4: "العنوان: غرفة 216، الطابق الثاني، المبنى أ، المرحلة 2، مدينة شانهاي يونهوي، رقم 1171 طريق شيويغانغ، مجتمع شيانغجياوتانغ، شارع بانتيان، منطقة لونغغانغ، مدينة شنتشن، مقاطعة قوانغدونغ (مخرج ب من محطة مترو جانجتو)",
        service: "خدمة عملاء وي شات"
    },
    footer: {
        title: "شركة إنفينيت كرييشن (شنتشن) للتكنولوجيا القابضة المحدودة جميع الحقوق محفوظة"
    }

};
